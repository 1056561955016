import { doc, setDoc, increment } from "firebase/firestore";
import { db, auth } from "../../../firebase/firebase";
import { fetchTodayData, updateTodayData } from "../utils/todayData";

export const updateActivity = async (node, incrementValue) => {
  const uid = auth.currentUser.uid;
  const todayData = await fetchTodayData(uid);
  const activities = todayData.activities || {};
  activities[node.id] = {
    title: node.title,
    timestamp: new Date().toISOString(),
    points: activities[node.id]?.points + incrementValue,
  };

  await updateTodayData(uid, { ...todayData, activities });
};
