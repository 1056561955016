import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

import { db, auth } from "../../firebase/firebase";
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { sendMessageToChatGPT } from "./api/openai";
import { getMessages } from "./api/messages";
import { formatTimestamp } from "./utils/timeUtils";
import { AudioRecorder } from "./AudioRecorder";
import { sendAudioToOpenAI } from "./api/sendAudioToOpenAI";

import "./ChatBox.css"; // Make sure to create this CSS file for styling

export const ChatBox = ({ uid, progressData }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [agents, setAgents] = useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [anchorDropDown, setAnchorDropDown] = React.useState(null);

  const openDropDown = Boolean(anchorDropDown);

  useEffect(() => {
    const fetchAgents = async () => {
      const lifeNodeQuery = query(
        collection(db, "progress"),
        where("parent", "==", "Life"),
        where("uid", "==", auth.currentUser.uid)
      );
      const querySnapshot = await getDocs(lifeNodeQuery);
      const agentDocuments = querySnapshot.docs.map((doc) => doc.data());
      setAgents(agentDocuments);
    };

    fetchAgents();

    // Subscribe to real-time updates from Firestore
    const messagesRef = collection(db, "messages");
    const q = query(messagesRef, where("uid", "==", uid));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const messagesFromFirestore = snapshot.docs
        .map((doc) => ({
          ...doc.data(),
          id: doc.id,
          timestamp: doc.data().timestamp.toDate(),
        }))
        .sort((a, b) => b.timestamp - a.timestamp); // Sort messages ascending by timestamp

      // Convert timestamp to string after sorting
      const messagesWithFormattedTimestamp = messagesFromFirestore.map(
        (msg) => ({
          ...msg,
          timestamp: msg.timestamp.toString(),
        })
      );

      setMessages(messagesWithFormattedTimestamp);
    });

    return unsubscribe; // Detach listener on unmount
  }, [uid]);

  const handleDropDownClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorDropDown(null);
  };

  const handleDropDownClose = () => {
    setAnchorDropDown(null);
  };

  const handleSendMessage = async (nMessage = "") => {
    if (!newMessage.trim() && !nMessage.trim()) return;

    const theMessage = newMessage.trim()
      ? newMessage
      : !nMessage.trim()
      ? nMessage
      : "";
    const userMessageTimestamp = new Date().toString();

    if (selectedIndex <= agents.length) {
      const agent = agents[selectedIndex];
      const otherAgents = [];
      agents.forEach((otherAgent) => {
        if (otherAgent.title !== agent.title) {
          otherAgents.push(otherAgent.title);
        }
      });

      const assistantMessage = await sendMessageToChatGPT(
        agent,
        otherAgents.join(", "),
        theMessage,
        progressData
      );
      setMessages([
        {
          agent: agent.title,
          photoURL: agent.photoURL,
          role: "assistant",
          content: assistantMessage,
          timestamp: new Date().toString(),
        },
        {
          role: "user",
          agent: agent.title,
          photoURL: agent.photoURL,
          content: theMessage,
          timestamp: userMessageTimestamp,
        },
        ...messages,
      ]);
      setNewMessage("");
    }
  };

  const handleAudioRecordEnd = async (audioBlob) => {
    // Convert blob to file here if needed
    // Send the audio file to OpenAI for transcription
    const transcribedText = await sendAudioToOpenAI(audioBlob);
    handleSendMessage(transcribedText);
  };

  return (
    <div className="chat-container">
      <div className="messages-list">
        {messages.map((msg, idx) => (
          <div key={idx} className={`message ${msg.role}`}>
            <div className="message-header">
              {msg.role === "assistant" && (
                <Avatar
                  alt={`${msg.agent} Profile Picture`}
                  src={msg.photoURL}
                />
              )}
              <div
                className="message-info"
                style={msg.role === "assistant" ? { marginLeft: "8px" } : {}}
              >
                <span className="sender">
                  {msg.role === "user" ? "You" : msg.agent}:
                </span>
                <span className="timestamp">
                  {formatTimestamp(msg.timestamp)}
                </span>
              </div>
              {msg.role === "user" && (
                <>
                  <ArrowRightAltIcon />
                  <Avatar
                    alt={`${msg.agent} Profile Picture`}
                    src={msg.photoURL}
                  />
                </>
              )}
            </div>
            <div className="message-content">
              {msg.content.split("\n").map((item, i) => (
                <p key={i}>{item}</p>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="input-area">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type a message..."
          onKeyDown={(e) => e.key === "Enter" && handleSendMessage()}
        />
        <Button
          id="basic-button"
          aria-controls={openDropDown ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openDropDown ? "true" : undefined}
          onClick={(event) => setAnchorDropDown(event.currentTarget)}
        >
          {selectedIndex <= agents.length ? (
            <>
              <Avatar
                alt={`${agents[selectedIndex].title} Profile Picture`}
                src={agents[selectedIndex].photoURL}
              />
              <span style={{ marginLeft: "10px" }}>
                {agents[selectedIndex].title}
              </span>
            </>
          ) : (
            "Choose Agent"
          )}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorDropDown}
          open={openDropDown}
          onClose={handleDropDownClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {agents.map((agent, index) => (
            <MenuItem
              key={agent.title}
              selected={index === selectedIndex}
              onClick={(event) => handleDropDownClick(event, index)}
            >
              <Avatar
                alt={`${agent.title} Profile Picture`}
                src={agent.photoURL}
              />
              <span style={{ marginLeft: "10px" }}>{agent.title}</span>
            </MenuItem>
          ))}
        </Menu>
        <button onClick={handleSendMessage}>Send</button>
      </div>
      {/* <AudioRecorder onRecordEnd={handleAudioRecordEnd} /> */}
    </div>
  );
};
