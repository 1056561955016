import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db, storage } from '../../firebase/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { TextField, Button, Select, MenuItem, InputLabel, FormControl, Typography } from '@mui/material';

function Profile() {
  const [userData, setUserData] = useState({
    firstname: '',
    lastname: '',
    gender: '',
    birthDate: '',
    photoURL: '',
  });
  const [newProfilePic, setNewProfilePic] = useState(null);

  useEffect(() => {
    // Fetch user data from Firestore
    const fetchData = async () => {
      const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
      if (userDoc.exists()) {
        setUserData(userDoc.data());
      }
    };
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleFileChange = (event) => {
    setNewProfilePic(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let photoURL = userData.photoURL;

    // Upload new profile picture if selected
    if (newProfilePic) {
      const storageRef = ref(storage, `profilePictures/${auth.currentUser.uid}`);
      const snapshot = await uploadBytes(storageRef, newProfilePic);
      photoURL = await getDownloadURL(snapshot.ref);
    }

    // Update Firestore user data
    await updateDoc(doc(db, 'users', auth.currentUser.uid), {
      ...userData,
      photoURL,
    });

    // Update local state
    setUserData({ ...userData, photoURL });
    setNewProfilePic(null);
  };

  return (
    <div style={{ maxWidth: '400px', margin: 'auto', padding: '20px' }}>
      <Typography variant="h6" gutterBottom>Edit Profile</Typography>
      <form onSubmit={handleSubmit}>
        {/* Existing form fields */}
        <input
          type="file"
          onChange={handleFileChange}
          accept="image/*"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
        >
          Update Profile
        </Button>
      </form>
      {userData.photoURL && (
        <img src={userData.photoURL} alt="Profile" style={{ width: '100px', height: '100px', objectFit: 'cover' }} />
      )}
    </div>
  );
}

export default Profile;
