import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { db, auth } from "../../../firebase/firebase";

export const getMessages = async (uid) => {
  const q = query(collection(db, "messages"), where("uid", "==", uid));
  const querySnapshot = await getDocs(q);
  const messages = querySnapshot.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
    // Ensure that the timestamp is converted to a Date object or a number for proper comparison
    timestamp: doc.data().timestamp.toDate().toString(),
  }));

  // Sort the messages by timestamp in ascending order
  messages.sort((a, b) => b.timestamp - a.timestamp);

  return messages;
};

export const addMessage = async (agent, uid, role, content) => {
  await addDoc(collection(db, "messages"), {
    uid,
    agent: agent.title,
    photoURL: agent.photoURL,
    role,
    content,
    timestamp: new Date(),
  });
};
