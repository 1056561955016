export const buildTree = (nodes) => {
  let tree = {};
  const nodeMap = new Map();

  // First, map all nodes by their title
  nodes.forEach((node) => {
    nodeMap.set(node.title, { ...node, children: [] });
  });

  // Then, build the tree structure
  nodes.forEach((node) => {
    if (node.parent) {
      const parentNode = nodeMap.get(node.parent);
      if (parentNode) {
        parentNode.children.push(nodeMap.get(node.title));
      } else {
        console.error(`Parent node not found for:`, node);
      }
    } else {
      tree = nodeMap.get(node.title);
    }
  });

  return tree;
};
