const firebaseErrorCodes = {
  "auth/invalid-email": "The email address is not valid.",
  'auth/invalid-credential': 'The provided credential is invalid. Please check and try again.',
  "auth/user-disabled":
    "The user corresponding to the provided email has been disabled.",
  "auth/user-not-found":
    "There is no user corresponding to the provided email.",
  "auth/wrong-password": "The password is invalid for the provided email.",
  "auth/email-already-in-use":
    "The email address is already in use by another account.",
  "auth/weak-password":
    "The password is too weak. Please choose a stronger password.",
  "auth/operation-not-allowed":
    "This operation is not allowed. Please contact support.",
  // Add more error codes as needed
};

export const getFirebaseErrorMessage = (errorCode) => {
  return (
    firebaseErrorCodes[errorCode] ||
    "An unexpected error occurred. Please try again."
  );
};
