import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../../firebase/firebase";

export const getTodayString = () => {
  return new Date().toISOString().split("T")[0];
};

export const fetchTodayData = async (uid) => {
  const todayDocRef = doc(db, "today", uid);
  const todayDocSnap = await getDoc(todayDocRef);
  return todayDocSnap.exists() ? todayDocSnap.data() : null;
};

export const updateTodayData = async (uid, data) => {
  const todayDocRef = doc(db, "today", uid);
  await setDoc(todayDocRef, data);
};

export const clearTodayData = async (uid) => {
  await updateTodayData(uid, { date: getTodayString() });
};
