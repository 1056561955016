import React from "react";
import { AppBar, Toolbar, Typography, Container, Box } from "@mui/material";

const Layout = ({ children }) => {
  return (
    <>
      <Container component="main" maxWidth="xs" sx={{ mt: 8, mb: 2 }}>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {children}
        </Box>
      </Container>
    </>
  );
};

export default Layout;
