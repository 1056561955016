import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../firebase/firebase";

export const fetchUserData = async (uid) => {
  const userDocRef = doc(db, "users", uid);
  const userDocSnap = await getDoc(userDocRef);

  if (userDocSnap.exists()) {
    return userDocSnap.data();
  } else {
    console.log("No such document!");
    return null;
  }
};

export const calculateAge = (birthdate) => {
  const birthday = new Date(birthdate);
  const today = new Date();
  let age = today.getFullYear() - birthday.getFullYear();
  const m = today.getMonth() - birthday.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
    age--;
  }
  return age;
};

export const getUserInfo = async (uid) => {
  const userData = await fetchUserData(uid);
  if (userData) {
    const age = calculateAge(userData.birthDate);
    return { firstname: userData.firstname, age: age };
  }
  return null;
};
