import { db, auth } from "../../../firebase/firebase";
import {
  doc,
  query,
  collection,
  where,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
} from "firebase/firestore";

const timerDocRef = doc(db, "timestamps", "timer");

export const fetchLastResetTime = async () => {
  const docSnap = await getDoc(timerDocRef);
  return docSnap.exists() ? docSnap.data().lastResetTime.toDate() : null;
};

export const updateLastResetTime = async () => {
  await setDoc(timerDocRef, { lastResetTime: new Date() });
};

export const setLastUpdated = async (agentTitle) => {
  const agentNodeQuery = query(
    collection(db, "progress"),
    where("title", "==", agentTitle),
    where("uid", "==", auth.currentUser.uid)
  );
  const querySnapshot = await getDocs(agentNodeQuery);
  if (querySnapshot.docs.length > 0) {
    const agentDocRef = doc(db, "progress", querySnapshot.docs[0].id);
    await updateDoc(agentDocRef, { lastResetTime: new Date() });
  }
};

export const lastUpdatedPlus = async (agentTitle, spacing) => {
  const agentNodeQuery = query(
    collection(db, "progress"),
    where("title", "==", agentTitle),
    where("uid", "==", auth.currentUser.uid)
  );
  const querySnapshot = await getDocs(agentNodeQuery);
  if (querySnapshot.docs.length > 0) {
    const agentDocRef = doc(db, "progress", querySnapshot.docs[0].id);
    const agentData = querySnapshot.docs[0].data();
    await updateDoc(agentDocRef, {
      lastResetTime: new Date(new Date().getTime() + spacing * 60000),
    });
  }
};
