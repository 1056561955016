const firebaseConfig = {
  apiKey: "AIzaSyD2kKBsY3hzniwH38BKDDzsdVqB_8bo_iE",
  authDomain: "assistant-49bb1.firebaseapp.com",
  projectId: "assistant-49bb1",
  storageBucket: "assistant-49bb1.appspot.com",
  messagingSenderId: "621821434639",
  appId: "1:621821434639:web:c872e81b0fc91c9eb6fc7a",
  measurementId: "G-77F6HEXE8P",
};
export default firebaseConfig;
