import OpenAI from "openai";
import { fetchTodayData } from "../utils/todayData";
import { lastUpdatedPlus } from "../utils/timerUtils";
import { getUserInfo } from "../utils/userData";
import { auth } from "../../../firebase/firebase";
import { getMessages, addMessage } from "./messages";
import openaiConfig from "./openaiConfig";

const openai = new OpenAI(openaiConfig);

const text2Speech = async (assistantVoice, message) => {
  try {
    const response = await openai.audio.speech.create({
      model: "tts-1",
      voice: assistantVoice,
      input: message,
    });

    // Convert the response to a Blob
    const blob = new Blob([await response.arrayBuffer()], {
      type: "audio/mp3",
    });

    // Create an audio element and set the source to the Blob URL
    const audio = new Audio(URL.createObjectURL(blob));

    // Play the audio
    audio.play();
  } catch (error) {
    console.error("Error generating speech: ", error);
  }
};

export const sendMessageToChatGPT = async (
  agent,
  otherAgents,
  userMessage = "",
  data
) => {
  const todayData = await fetchTodayData(auth.currentUser.uid);
  const userInfo = await getUserInfo(auth.currentUser.uid);
  const messages = await getMessages(auth.currentUser.uid);

  await lastUpdatedPlus(agent.title, 10);

  let systemMessage =
    "Every time I do anything, I mark it and increment my points associated with that activity in the following JSON object:\n";
  systemMessage += `${JSON.stringify(data)}.\n`;
  systemMessage += `You are ${agent.title}. So, you should focus only on my activities listed under ${agent.title}; i.e., you should not encourage me to do activities whose parent or ancestor node is not ${agent.title}.\n`;
  systemMessage += "Generate a JSON output with the following schema:\n";
  systemMessage += "{\n";
  systemMessage += `"agent": ${agent.title},\n`;
  systemMessage += '"message": "Your message to me goes here.",\n';
  // systemMessage +=
  //   '"activity_improvements": "Suggest improvements that I can make to my activities JSON object as a JSON object with the following structure:"\n';
  // systemMessage += "{\n";
  // systemMessage +=
  //   '"additions": [An array of objects, each indicating an object with the structure {"title": "The title of the activity to be added", "parent": "The title of the parent activity"} to add to my JSON object of activities],\n';
  // systemMessage +=
  //   '"modifications": [An array of objects, each indicating an object with the structure {"old_title": "The current title of the activity to be modified", "new_title": "The new title of the activity to be modified", "old_parent": "The title of the current parent activity", "new_parent": "The title of the new parent activity"} to modify in my JSON object of activities],\n';
  // systemMessage +=
  //   '"deletions": [An array of objects, each indicating an object with the structure {"title": "The title of the activity to be deleted", "parent": "The title of the parent activity"} to delete from my JSON object of activities],\n';
  // systemMessage += "},\n";
  systemMessage +=
    '"suggested_accomplishments": Sometimes I have difficulties deciding which items in my JSON object of activities I should give points to, for what I just accomplished. Only in that case, I describe to you what I just accomplished and you should give me [an array of suggested items with structure {"title": "title of the activity for which I should receive a point", "parent": "The parent of the activity for which I should receive a point"} in my activities JSON objects], otherwise, just give me an empty array []\n';
  systemMessage += "}\n";
  systemMessage += agent.instructions + "\n";
  systemMessage +=
    "You are provided with all my activities data, but note that there are other AI agents: " +
    otherAgents +
    "\n";
  systemMessage +=
    "You have access to my full communication with the other agents. The other agents also have access to your full communication with me.\n";
  systemMessage +=
    "So, if you'd like to signal anything to the other agents, you can include them in your messages to me.\n";
  systemMessage +=
    "However, your messages to me should be as short as possible, otherwise I'll lose my motivation to read your messages.\n";
  systemMessage += "I keep track of my granular activities in a JSON object.\n";
  systemMessage +=
    "You can ask me questions to better understand me and my circumstances.\n";
  systemMessage +=
    "Do not give me generic advice. Your messages should be very short and tailored to my specific activities or habits. Your messages can also include short motivational quotes.\n";

  // Include additional assistant messages based on userInfo.
  if (userInfo) {
    if (userInfo.firstname) {
      systemMessage += ` My first name is ${userInfo.firstname}.\n`;
    }
    if (userInfo.age) {
      systemMessage += ` I'm ${userInfo.age} years old.\n`;
    }
  }

  // Send today's activities as a separate assistant message.
  systemMessage += `Now is ${new Date().toString()}. My today's accomplishments so far are: \n${JSON.stringify(
    todayData
  )}`;

  const systemMessageObj = {
    role: "system",
    content: systemMessage,
  };

  const userMessageObjs = [];

  for (let messgge of messages) {
    userMessageObjs.push({
      role: messgge.role,
      content:
        messgge.role === "user"
          ? messgge.content
          : '{\n"agent": ' +
            agent.title +
            ',\n"message": ' +
            messgge.content +
            "\n}",
    });
  }
  if (userMessage && userMessage !== "") {
    userMessageObjs.push({
      role: "user",
      content: userMessage,
    });
    await addMessage(agent, auth.currentUser.uid, "user", userMessage);
  }

  const messagePayload = {
    model: "gpt-4-turbo-preview",
    messages: [systemMessageObj, ...userMessageObjs],
    response_format: { type: "json_object" },
  };

  let responseObj = false;
  while (
    !responseObj ||
    !responseObj.hasOwnProperty("message") ||
    responseObj.message === ""
  ) {
    const response = await openai.chat.completions.create(messagePayload);

    try {
      responseObj = JSON.parse(response.choices[0].message.content);
    } catch (err) {
      console.error(err.message);
    }
  }
  let responseMessage = responseObj.message;
  await text2Speech(agent.voice, responseMessage);

  // const improvements = responseObj.activity_improvements;
  // if (improvements?.additions?.length > 0) {
  //   for (let addition of improvements.additions) {
  //     if (addition.title && addition.parent) {
  //       responseMessage +=
  //         "\n\nI suggest you add a new activity under " +
  //         addition.parent +
  //         " with the title " +
  //         addition.title +
  //         "\n";
  //     }
  //   }
  // }
  // if (improvements?.modifications?.length > 0) {
  //   for (let modification of improvements.modifications) {
  //     if (modification.old_title && modification.old_parent) {
  //       responseMessage +=
  //         "\n\nI suggest you modify the activity under " +
  //         modification.old_parent +
  //         " as follows:\n";
  //       if (modification.new_parent) {
  //         responseMessage +=
  //           "Change its parent from " +
  //           modification.old_parent +
  //           " to " +
  //           modification.new_parent +
  //           "\n";
  //       }
  //       if (modification.new_title) {
  //         responseMessage +=
  //           "Change its title from " +
  //           modification.old_title +
  //           " to " +
  //           modification.new_title +
  //           "\n";
  //       }
  //     }
  //   }
  // }
  // if (improvements?.deletions?.length > 0) {
  //   for (let deletion of improvements.deletions) {
  //     if (deletion.title && deletion.parent) {
  //       responseMessage +=
  //         "\n\nI suggest you delete the activity under " +
  //         deletion.parent +
  //         " with the title " +
  //         deletion.title +
  //         "\n";
  //     }
  //   }
  // }
  // const suggestedAccomplishments = responseObj.suggested_accomplishments;
  // if (suggestedAccomplishments?.length > 0) {
  //   for (let suggestedAccomplishment of suggestedAccomplishments) {
  //     if (suggestedAccomplishment.title && suggestedAccomplishment.parent) {
  //       responseMessage +=
  //         "\n\nI suggest you give yourself a point for the activity under " +
  //         suggestedAccomplishment.parent +
  //         " with the title " +
  //         suggestedAccomplishment.title +
  //         "\n";
  //     }
  //   }
  // }

  // Save the assistant's message
  await addMessage(agent, auth.currentUser.uid, "assistant", responseMessage);
  // return { message: responseMessage, suggestions: suggestedAccomplishments };
  return responseMessage;
};
