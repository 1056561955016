import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, doc, writeBatch, setDoc } from "firebase/firestore";
import { auth, db, storage } from "../../firebase/firebase";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
} from "@mui/material";

function Register() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [gender, setGender] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  const [error, setError] = useState("");

  const handleFileChange = (event) => {
    setProfilePicture(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      let photoURL = "";
      if (profilePicture) {
        const storageRef = ref(storage, `profilePictures/${user.uid}`);
        const snapshot = await uploadBytes(storageRef, profilePicture);
        photoURL = await getDownloadURL(snapshot.ref);
      }

      await updateProfile(user, {
        displayName: firstname + " " + lastname,
        photoURL,
      });

      // Get a new write batch
      const batch = writeBatch(db);

      batch.set(doc(db, "users", user.uid), {
        firstname,
        lastname,
        email,
        gender,
        birthDate,
        photoURL,
      });

      const progressTitles = [
        "Life", "1", "Morning", "Noon", "Afternoon", "Evening", "Night", "Exercise", "Research", "Food", "Water", "Social"
      ];

      progressTitles.forEach(title => {
        const progressDocRef = doc(db, "progress", `${user.uid}_${title}`);
        batch.set(progressDocRef, {
          uid: user.uid,
          title: title,
          points: 0,
          parent: title === "Life" ? null : (["Morning", "Noon", "Afternoon", "Evening", "Night"].includes(title) ? "1" : "Life")
        });
      });

      // Commit the batch
      await batch.commit();

      // Redirect to dashboard or show success message
      navigate("/dashboard");
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div style={{ maxWidth: "400px", margin: "auto", padding: "20px" }}>
      <Typography variant="h6" gutterBottom>
        Register
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <form onSubmit={handleSubmit}>
        <TextField
          fullWidth
          label="Email"
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          fullWidth
          label="Password"
          type="password"
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField
          fullWidth
          label="First Name"
          margin="normal"
          value={firstname}
          onChange={(e) => setFirstname(e.target.value)}
        />
        <TextField
          fullWidth
          label="Last Name"
          margin="normal"
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Gender</InputLabel>
          <Select
            value={gender}
            label="Gender"
            onChange={(e) => setGender(e.target.value)}
          >
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Select>
        </FormControl>
        <TextField
          fullWidth
          label="Birth Date"
          type="date"
          margin="normal"
          InputLabelProps={{ shrink: true }}
          value={birthDate}
          onChange={(e) => setBirthDate(e.target.value)}
        />
        <input type="file" onChange={handleFileChange} accept="image/*" />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 3, mb: 2 }}
        >
          Register
        </Button>
      </form>
    </div>
  );
}

export default Register;
